import React from "react";

import { Link } from "gatsby";

import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { usePostedMonthsList, useSiteMetadata } from "@/hooks";
import { formattedDayString, toKebabCase } from "@/utils";

const PostedMonthsTemplate: React.FC = () => {
  const months = usePostedMonthsList();

  return (
    <Layout>
      <Sidebar />
      <Page title="Posted Months">
        <ul>
          {months.map((month) => (
            <li key={month.fieldValue}>
              <Link to={`/month/${toKebabCase(month.fieldValue)}/`}>
                {formattedDayString.getFormattedPostedMonthString({
                  month: month.fieldValue,
                })}{" "}
                ({month.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
    </Layout>
  );
};

export const Head: React.FC = () => {
  const { title, subtitle } = useSiteMetadata();
  const pageTitle = `Posted Months - ${title}`;

  return <Meta title={pageTitle} description={subtitle} />;
};

export default PostedMonthsTemplate;
